import { BoxAlign } from '@nivo/core'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'
import { LockIcon } from '~/core/ui/FillIcons'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import {
  InlineEditingEditor,
  InlineEditingInput,
  InlineEditingInputNumber
} from '~/core/ui/InlineEditing'
import {
  InlineEditingNoActionsDateOrYearPicker,
  InlineEditingNoActionsNativeSelect
} from '~/core/ui/InlineEditingNoActions'
import { Input } from '~/core/ui/Input'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { RichEditor, RichEditorSizeProps } from '~/core/ui/RichEditor'
import { ISelectOption } from '~/core/ui/Select'
import {
  formatDatePickerToDate,
  SingleDateWithYearOnlyPicker
} from '~/core/ui/SingleDateWithYearOnlyPicker'
import { TypographyText } from '~/core/ui/Text'
import { Toggle } from '~/core/ui/Toggle'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { defaultFormatDate } from '~/core/utilities/format-date'
import LongContentDisplay from '../LongContentDisplay'

export interface CustomFieldComponentType {
  type: 'text' | 'number' | 'paragraph' | 'toggle' | 'select' | 'date'
  display: 'view_horizontal' | 'inline_editing' | 'default'
  isLoadingSubmitButton?: boolean
  name: string
  label?: string
  labelRequired?: boolean
  value?: any
  lockContent?: string
  onChange: (value: any) => void
  error?: FieldErrors<any>
  editingTriggerValidate?: () => Promise<boolean>
  viewDefaultPlaceholder?: string
  extraProps?: {
    options?: ISelectOption[]
  }
  classNameConfig?: {
    [type: string]: string
  }
  clientUserVisibility?: boolean
  classNameInlineEditting?: string
  classNameText?: string
  sizeField?: RichEditorSizeProps
  showIconHorizontalView?: boolean
}

export const mappingCustomFieldIcon = (type: string): LucideIconName =>
  ((
    {
      text: 'Type',
      number: 'Hash',
      paragraph: 'AlignLeft',
      toggle: 'CheckSquare',
      select: 'ListChecks',
      date: 'Calendar'
    } as { [key: string]: LucideIconName }
  )[type])

const InlineEditingLayout: FCC<{
  iconMenus?: LucideIconName
  lockContent?: string
  label?: string
  clientUserVisibility?: boolean
  className?: string
  classNameText?: string
  sizeField?: RichEditorSizeProps
}> = ({
  children,
  iconMenus,
  lockContent,
  label,
  clientUserVisibility,
  className,
  classNameText = 'text-sm',
  sizeField = 'sm'
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <div className="flex items-center space-x-2 py-1">
          {iconMenus && (
            <IconWrapper
              size={16}
              name={iconMenus}
              className="hidden flex-none text-gray-600 desktop:block"
            />
          )}
          <Tooltip content={label}>
            <TypographyText
              className={cn(
                'line-clamp-1 break-all text-gray-700',
                classNameText
              )}>
              {label}
            </TypographyText>
          </Tooltip>
          <If condition={clientUserVisibility === false}>
            <Tooltip
              classNameConfig={{ content: 'max-w-full' }}
              content={t('tooltip:client_cannot_view')}>
              <LockIcon />
            </Tooltip>
          </If>
          {lockContent && (
            <Tooltip
              classNameConfig={{ content: 'max-w-full' }}
              content={lockContent}>
              <LockIcon />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={className}>{children}</div>
    </>
  )
}

const CustomField = ({
  display = 'default',
  viewDefaultPlaceholder = '-',
  isLoadingSubmitButton = false,
  label,
  labelRequired,
  name,
  type,
  value,
  lockContent,
  onChange,
  error,
  editingTriggerValidate,
  extraProps,
  classNameConfig,
  clientUserVisibility,
  classNameInlineEditting = '',
  classNameText = 'text-sm',
  sizeField = 'sm',
  showIconHorizontalView = true
}: CustomFieldComponentType) => {
  const { t } = useTranslation()
  const defaultFormControlProps = {
    destructive: error && !!error?.[name],
    destructiveText: error && (error?.[name]?.message as string)
  }

  const defaultControlProps = {
    value,
    onChange
  }

  return (
    (
      {
        default: {
          text: (
            <FormControlItem
              label={label}
              labelRequired={labelRequired}
              {...defaultFormControlProps}>
              <Input
                size={sizeField}
                {...defaultControlProps}
                placeholder={`${t('form:additional_field_input', {
                  field_name: label
                })}`}
                destructive={defaultFormControlProps.destructive}
                className={classNameConfig?.text}
              />
            </FormControlItem>
          ),
          number: (
            <FormControlItem
              label={label}
              labelRequired={labelRequired}
              {...defaultFormControlProps}>
              <Input
                size={sizeField}
                inputType="number"
                placeholder={`${t('form:additional_field_input', {
                  field_name: label
                })}`}
                {...defaultControlProps}
                destructive={defaultFormControlProps.destructive}
                className={classNameConfig?.number}
              />
            </FormControlItem>
          ),
          paragraph: (
            <FormControlItem
              label={label}
              labelRequired={labelRequired}
              {...defaultFormControlProps}>
              <RichEditor
                size={sizeField}
                limit={10000}
                placeholder={`${t('form:additional_field_input', {
                  field_name: label
                })}`}
                onChange={(value) => {
                  defaultControlProps.onChange(value)
                }}
                content={defaultControlProps.value}
                destructive={defaultFormControlProps.destructive}
                classNameWrapper={`min-h-[152px] ${classNameConfig?.paragraphWrapper}`}
                className={classNameConfig?.paragraph}
              />
            </FormControlItem>
          ),
          toggle: (
            <Toggle
              isChecked={defaultControlProps.value}
              name="view-switch"
              onCheckedChange={defaultControlProps.onChange}
              size={sizeField}
              text={label}
              toggle="trailing"
              className={classNameConfig?.toggle}
            />
          ),
          select: (() => {
            const selectedOption = (extraProps?.options || []).find(
              (option) => String(option.value) === value
            )
            return (
              <FormControlItem
                label={label}
                labelRequired={labelRequired}
                {...defaultFormControlProps}>
                <NativeSelect
                  showDropdownIndicator={true}
                  isClearable
                  placeholder={`${t('form:additional_field_select')}`}
                  size="md"
                  value={selectedOption}
                  onChange={(selectOption) => {
                    onChange(
                      (selectOption as ISelectOption)?.value || undefined
                    )
                  }}
                  options={extraProps?.options || []}
                  destructive={defaultFormControlProps.destructive}
                  classNameOverride={{
                    loadingMessage: `${t('label:loading')}`,
                    noOptionsMessage: `${t('label:noOptions')}`
                  }}
                />
              </FormControlItem>
            )
          })(),
          date: (
            <FormControlItem
              label={label}
              labelRequired={labelRequired}
              {...defaultFormControlProps}>
              <SingleDateWithYearOnlyPicker
                type="default"
                config={{
                  onChange: (value) => defaultControlProps.onChange(value),
                  value: defaultControlProps.value,
                  showClearIndicator: true
                  // onClear: () => {
                  //   onChange({
                  //     date: null,
                  //     month: null,
                  //     year: null
                  //   })
                  // }
                }}
                defaultTab={
                  !defaultControlProps.value?.month &&
                  !defaultControlProps.value?.date &&
                  !!defaultControlProps.value?.year
                    ? 'year'
                    : 'date'
                }
              />
            </FormControlItem>
          )
        },
        inline_editing: {
          text: (
            <InlineEditingLayout
              label={label}
              lockContent={lockContent}
              classNameText={classNameText}
              iconMenus={mappingCustomFieldIcon(type)}
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <InlineEditingInput
                onChange={(newValue) => {
                  onChange(newValue)
                  return editingTriggerValidate
                    ? editingTriggerValidate()
                    : Promise.resolve(true)
                }}
                onCancelUpdate={(newValue) => onChange(newValue)}
                placeholder={
                  t('form:additional_field_add', {
                    field_name: label
                  }) as string
                }
                size={sizeField}
                className="-mx-px"
                loadingSubmit={isLoadingSubmitButton}
                value={value}
                tooltipActionCancel={{
                  title: `${t('button:cancel')}`
                }}
                tooltipActionSave={{
                  title: `${t('button:saveEnter')}`
                }}
                destructiveText={defaultFormControlProps.destructiveText}>
                <div
                  className={cn(
                    'flex items-center px-2 py-1.5 text-sm',
                    classNameText
                  )}>
                  {!!String(value || '').trim() && typeof value === 'string' ? (
                    <Tooltip content={value}>
                      <div className="line-clamp-1 text-gray-900">{value}</div>
                    </Tooltip>
                  ) : (
                    <div className="line-clamp-1 text-gray-600">
                      {t('form:additional_field_add', {
                        field_name: label
                      })}
                    </div>
                  )}
                </div>
              </InlineEditingInput>
            </InlineEditingLayout>
          ),
          number: (
            <InlineEditingLayout
              label={label}
              lockContent={lockContent}
              iconMenus={mappingCustomFieldIcon(type)}
              clientUserVisibility={clientUserVisibility}
              classNameText={classNameText}
              className={classNameInlineEditting}>
              <InlineEditingInputNumber
                onChange={(newValue) => {
                  onChange(newValue)
                  return editingTriggerValidate
                    ? editingTriggerValidate()
                    : Promise.resolve(true)
                }}
                onCancelUpdate={(newValue) => onChange(newValue)}
                placeholder={
                  t('form:additional_field_add', {
                    field_name: label
                  }) as string
                }
                size={sizeField}
                className="-mx-px"
                loadingSubmit={isLoadingSubmitButton}
                value={value}
                tooltipActionCancel={{
                  title: `${t('button:cancel')}`
                }}
                tooltipActionSave={{
                  title: `${t('button:saveEnter')}`
                }}
                destructiveText={defaultFormControlProps.destructiveText}>
                <div
                  className={cn('flex items-center px-2 py-1', classNameText)}>
                  {!!value ? (
                    <div className="text-gray-900">{value}</div>
                  ) : (
                    <div className="line-clamp-1 text-gray-600">
                      {t('form:additional_field_add', {
                        field_name: label
                      })}
                    </div>
                  )}
                </div>
              </InlineEditingInputNumber>
            </InlineEditingLayout>
          ),
          paragraph: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              lockContent={lockContent}
              iconMenus={mappingCustomFieldIcon(type)}
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <InlineEditingEditor
                autoSave
                localStorageId={`custom-field-${name}`}
                className="min-w-full"
                limit={10000}
                showCount={true}
                loadingSubmit={isLoadingSubmitButton}
                destructiveText={defaultFormControlProps.destructiveText}
                onChange={(newValue) => {
                  onChange(newValue)
                  return editingTriggerValidate
                    ? editingTriggerValidate()
                    : Promise.resolve(true)
                }}
                placeholder={`${t('form:additional_field_add', {
                  field_name: label
                })}`}
                size={sizeField}
                value={value}
                tooltipActionCancel={{
                  title: `${t('button:cancel')}`
                }}
                tooltipActionSave={{
                  title: `${t('button:saveEnter')}`
                }}>
                <div
                  className={cn('flex items-center px-2 py-1', classNameText)}>
                  {value ? (
                    <LongContentDisplay
                      isHTML={true}
                      content={value}
                      limitLines={3}
                      textButtonProps={{
                        label: `${t('common:show_more')}`
                      }}
                      className={cn(
                        'max-w-full text-gray-900 prose-p:!min-h-0',
                        classNameText
                      )}
                    />
                  ) : (
                    <TypographyText
                      className={cn(
                        'line-clamp-1 text-gray-600',
                        classNameText
                      )}>
                      {t('form:additional_field_add', {
                        field_name: label
                      })}
                    </TypographyText>
                  )}
                </div>
              </InlineEditingEditor>
            </InlineEditingLayout>
          ),
          toggle: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              lockContent={lockContent}
              iconMenus={mappingCustomFieldIcon(type)}
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <div className="px-2 py-1">
                <Toggle
                  isChecked={defaultControlProps.value}
                  isDisabled={isLoadingSubmitButton}
                  name="view-switch"
                  onCheckedChange={(checked) => {
                    defaultControlProps.onChange(checked)
                    return editingTriggerValidate
                      ? editingTriggerValidate()
                      : Promise.resolve(true)
                  }}
                  size={sizeField}
                />
              </div>
            </InlineEditingLayout>
          ),
          select: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              lockContent={lockContent}
              iconMenus={mappingCustomFieldIcon(type)}
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <InlineEditingNoActionsNativeSelect
                onChange={(newValue) => {
                  onChange(newValue?.value)
                  return editingTriggerValidate
                    ? editingTriggerValidate()
                    : Promise.resolve(true)
                }}
                isDisabled={isLoadingSubmitButton}
                isClearable
                onCancelUpdate={(newValue) => {
                  onChange(newValue?.value)
                }}
                options={extraProps?.options || []}
                placeholder={t('form:additional_field_select') as string}
                size={sizeField}
                classNameOverride={{
                  loadingMessage: `${t('label:loading')}`,
                  noOptionsMessage: `${t('label:noOptions')}`
                }}
                value={(extraProps?.options || [])?.find(
                  (option) => option.value === value
                )}>
                <div className={cn('px-2 py-1', classNameText)}>
                  {!!(extraProps?.options || [])?.find(
                    (option) => option.value === value
                  ) ? (
                    <div className="line-clamp-1 text-gray-900">
                      {
                        (extraProps?.options || [])?.find(
                          (option) => option.value === value
                        )?.supportingObj?.name
                      }
                    </div>
                  ) : (
                    <div className="line-clamp-1 text-gray-600">
                      {t('form:additional_field_select') as string}
                    </div>
                  )}
                </div>
              </InlineEditingNoActionsNativeSelect>
            </InlineEditingLayout>
          ),
          date: (
            <InlineEditingLayout
              label={label}
              lockContent={lockContent}
              iconMenus={mappingCustomFieldIcon(type)}
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <InlineEditingNoActionsDateOrYearPicker
                inlineType="default"
                onChange={(newValue) => {
                  onChange(newValue)
                  return editingTriggerValidate
                    ? editingTriggerValidate()
                    : Promise.resolve(true)
                }}
                // onCancelUpdate={(newValue) => onChange(newValue)}
                placeholder={
                  t('form:additional_field_add', {
                    field_name: label
                  }) as string
                }
                value={value}
                destructiveText={defaultFormControlProps.destructiveText}>
                <div
                  className={cn(
                    'px-2 py-1.5 text-sm',
                    classNameText,
                    value?.year && typeof value === 'object'
                      ? 'text-gray-900'
                      : 'text-gray-600'
                  )}>
                  {value?.year && typeof value === 'object'
                    ? !!value?.year && !value.month && !value.date
                      ? value.year
                      : defaultFormatDate(formatDatePickerToDate(value))
                    : t('form:additional_field_select')}
                </div>
              </InlineEditingNoActionsDateOrYearPicker>
            </InlineEditingLayout>
          )
        },
        view_horizontal: {
          text: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              iconMenus={
                showIconHorizontalView
                  ? mappingCustomFieldIcon(type)
                  : undefined
              }
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <div
                className={cn(
                  'px-2 py-1',
                  classNameText,
                  value ? 'text-gray-900' : 'text-gray-600'
                )}>
                {typeof value === 'string'
                  ? value || viewDefaultPlaceholder
                  : ''}
              </div>
            </InlineEditingLayout>
          ),
          number: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              iconMenus={
                showIconHorizontalView
                  ? mappingCustomFieldIcon(type)
                  : undefined
              }
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <div
                className={cn(
                  'px-2 py-1',
                  classNameText,
                  value ? 'text-gray-900' : 'text-gray-600'
                )}>
                {value || viewDefaultPlaceholder}
              </div>
            </InlineEditingLayout>
          ),
          paragraph: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              iconMenus={
                showIconHorizontalView
                  ? mappingCustomFieldIcon(type)
                  : undefined
              }
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <div className="px-2 py-1">
                <LongContentDisplay
                  isHTML={true}
                  content={value || viewDefaultPlaceholder}
                  limitLines={3}
                  textButtonProps={{
                    label: `${t('common:show_more')}`
                  }}
                  className={cn(
                    'max-w-full prose-p:!min-h-0',
                    classNameText,
                    value ? 'text-gray-900' : 'text-gray-600'
                  )}
                />
              </div>
            </InlineEditingLayout>
          ),
          toggle: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              iconMenus={
                showIconHorizontalView
                  ? mappingCustomFieldIcon(type)
                  : undefined
              }
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <div className="flex px-2 py-1">
                <Toggle
                  isChecked={defaultControlProps.value}
                  isDisabled={true}
                  name="view-switch"
                  size={sizeField}
                />
              </div>
            </InlineEditingLayout>
          ),
          select: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              iconMenus={
                showIconHorizontalView
                  ? mappingCustomFieldIcon(type)
                  : undefined
              }
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <div
                className={cn(
                  'px-2 py-1',
                  classNameText,
                  value ? 'text-gray-900' : 'text-gray-600'
                )}>
                {(extraProps?.options || []).find(
                  (option) =>
                    String(option.value) === String(defaultControlProps.value)
                )?.supportingObj?.name || viewDefaultPlaceholder}
              </div>
            </InlineEditingLayout>
          ),
          date: (
            <InlineEditingLayout
              label={label}
              classNameText={classNameText}
              iconMenus={
                showIconHorizontalView
                  ? mappingCustomFieldIcon(type)
                  : undefined
              }
              clientUserVisibility={clientUserVisibility}
              className={classNameInlineEditting}>
              <div
                className={cn(
                  'px-2 py-1.5 text-sm',
                  classNameText,
                  value?.year && typeof value === 'object'
                    ? 'text-gray-900'
                    : 'text-gray-600'
                )}>
                {value?.year && typeof value === 'object'
                  ? !!value?.year && !value.month && !value.date
                    ? value.year
                    : defaultFormatDate(formatDatePickerToDate(value))
                  : viewDefaultPlaceholder}
              </div>
            </InlineEditingLayout>
          )
        }
      } as {
        [key: string]: {
          [key: string]: React.ReactElement
        }
      }
    )[display][type] || type
  )
}

export default CustomField
